import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';

const WhatsAppTalk = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`🌤 Save our Future! 🙌

Forward this message to five top friends and groups, and let’s make a #FinalSay go viral!
CLICK ➡➡➡ http://saveourfuture.uk/talk ⬅⬅⬅ HERE

Time is running out to save the UK from Brexit. Send your MP a 60 sec voice message:
*DEMAND A 🗣➡➡➡ http://FINALSAY.app * ⬅⬅⬅ 🗣`}
    />
    <Interstitial />
  </Layout>
);

WhatsAppTalk.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppTalk;

export const query = graphql`
  query TalkQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
